import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";

const dark = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#121212",
      dark: "#121212",
    },
    secondary: {
      main: green[500],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#FFF",
        },
        contained: {
          backgroundColor: "#292929",
        },
        outlined: {
          "&:hover": {
            borderColor: "#FFF",
          },
          borderColor: "#FFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#e8e8e8",
          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#e8e8e8",
        },
      },
    },
  },
  header: {
    color: "#e8e8e8 !important",
    fontWeight: "700 !important",
  },
  body: {
    color: "#e8e8e8 !important",
  },
  problemCatagory: {
    fontSize: "26px",
    padding: "20px",
    color: "#e8e8e8 !important",
    filter: "brightness(1)",
  },
  card: {
    "&::before": {
      backgroundColor: "rgb(9 9 9 / 30%)",
      backdropFilter:
        "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
      content: '""',
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    border: "1px solid rgb(9 9 9 / 30%)",
    color: "#e8e8e8 !important",
    borderRadius: "15px",
    boxShadow: "0 0 1rem 0 rgb(0 0 0 / 10%)",
    fontSize: "2rem",
    height: "220px",
    overflow: "hidden",
    position: "relative",
    width: "100%",
    cursor: "pointer",
  },
  cardContent: {
    color: "#e8e8e8",
    fontSize: "24px",
    marginLeft: "30px",
    marginTop: "20px",
    position: "relative",
  },
  cardContentCount: {
    color: "#e8e8e8",
    fontSize: "24px",
    marginLeft: "30px",
    marginTop: "100px",
    position: "relative",
  },
  appBarColored: {
    backgroundColor: "#121212 !important",
  },
  footerCard: {
    "&::before": {
      backgroundColor: "rgb(9 9 9 / 30%)",
      backdropFilter:
        "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
      content: '""',
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    border: "1px solid rgb(9 9 9 / 30%)",
    color: "#e8e8e8 !important",
    boxShadow: "0 0 1rem 0 rgb(0 0 0 / 10%)",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  footerCardContent: {
    color: "#e8e8e8",
    position: "relative",
    fontWeight: "bold",
  },
  footerKeywords: {
    padding: "2px 6px",
    background: "#1313133d",
    borderRadius: "4px",
    margin: "5px",
    display: "inline-grid",
  },
});

export default dark;
