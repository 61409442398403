import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import dark from "./themes/dark";
import light from "./themes/light";

const App = () => {
  const [theme, setTheme] = useState("dark");
  return (
    <ThemeProvider theme={theme === "light" ? light : dark}>
      <div className={theme === "light" ? "home-bk" : "home-bk-dark"} />
      <div className="App">
        <AppRoutes
          currentTheme={(theme) => {
            setTheme(theme);
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default App;
