/* eslint-disable react/jsx-no-target-blank */
import MailIcon from "@mui/icons-material/Mail";
import MessageIcon from "@mui/icons-material/Message";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import "./Footer.css";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  footerCard: theme.footerCard,
  footerCardContent: theme.footerCardContent,
  footerKeywords: theme.footerKeywords,
  gradientText: {
    background:
      "linear-gradient(96.2deg, #ED6419 -3.08%, #9629E7 14.74%, #c76508 72.12%);",
    fontSize: "20px",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    whiteSpace: "wrap",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "normal",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [pageVisit, setPageVisit] = useState(new Map());
  const [showStats, setShowStats] = useState(false);
  const [last30DaysVisits, setLast30DaysVisits] = useState(0);
  const [last30DaysUniqueVisits, setLast30DaysUniqueVisits] = useState(0);
  const [last7DaysVisits, setLast7DaysVisits] = useState(0);
  const [last7DaysUniqueVisits, setLast7DaysUniqueVisits] = useState(0);
  const [last60DaysVisits, setLast60DaysVisits] = useState(0);
  const [last60DaysUniqueVisits, setLast60DaysUniqueVisits] = useState(0);
  const [last90DaysVisits, setLast90DaysVisits] = useState(0);
  const [last90DaysUniqueVisits, setLast90DaysUniqueVisits] = useState(0);
  const [last60_30DaysVisits, setLast60_30DaysVisits] = useState(0);
  const [last60_30DaysUniqueVisits, setLast60_30DaysUniqueVisits] = useState(0);
  const [last90_30DaysVisits, setLast90_30DaysVisits] = useState(0);
  const [last90_30DaysUniqueVisits, setLast90_30DaysUniqueVisits] = useState(0);

  useEffect(() => {
    const uniqueUserCount = async () => {
      fetch(
        `https://perpverse-default-rtdb.asia-southeast1.firebasedatabase.app/telemetry-app-visit.json`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          const last7Days = moment().subtract(7, "days");
          const last30Days = moment().subtract(30, "days");
          const last60Days = moment().subtract(60, "days");
          const last90Days = moment().subtract(90, "days");

          let visitsCount30Days = 0;
          let uniqueVisitsSet30Days = new Set();
          let visitsCount7Days = 0;
          let uniqueVisitsSet7Days = new Set();
          let visitsCount60Days = 0;
          let uniqueVisitsSet60Days = new Set();
          let visitsCount90Days = 0;
          let uniqueVisitsSet90Days = new Set();
          let visitsCount60_30Days = 0;
          let uniqueVisitsSet60_30Days = new Set();
          let visitsCount90_30Days = 0;
          let uniqueVisitsSet90_30Days = new Set();

          Object.keys(res).forEach((item) => {
            const sessionId = res[item].sessionId;
            const visitDate = moment(res[item].sigInTime);

            if (visitDate.isSameOrAfter(last30Days)) {
              visitsCount30Days++;
              uniqueVisitsSet30Days.add(sessionId);
            }
            if (visitDate.isSameOrAfter(last7Days)) {
              visitsCount7Days++;
              uniqueVisitsSet7Days.add(sessionId);
            }
            if (visitDate.isSameOrAfter(last60Days)) {
              visitsCount60Days++;
              uniqueVisitsSet60Days.add(sessionId);
            }
            if (visitDate.isSameOrAfter(last90Days)) {
              visitsCount90Days++;
              uniqueVisitsSet90Days.add(sessionId);
            }
            if (
              visitDate.isSameOrAfter(last60Days) &&
              visitDate.isBefore(last30Days)
            ) {
              visitsCount60_30Days++;
              uniqueVisitsSet60_30Days.add(sessionId);
            }
            if (
              visitDate.isSameOrAfter(last90Days) &&
              visitDate.isBefore(last60Days)
            ) {
              visitsCount90_30Days++;
              uniqueVisitsSet90_30Days.add(sessionId);
            }
          });

          setUsers(Object.keys(res).map((appVisit) => res[appVisit].sessionId));
          setLast30DaysVisits(visitsCount30Days);
          setLast30DaysUniqueVisits(uniqueVisitsSet30Days.size);
          setLast7DaysVisits(visitsCount7Days);
          setLast7DaysUniqueVisits(uniqueVisitsSet7Days.size);
          setLast60DaysVisits(visitsCount60Days);
          setLast60DaysUniqueVisits(uniqueVisitsSet60Days.size);
          setLast90DaysVisits(visitsCount90Days);
          setLast90DaysUniqueVisits(uniqueVisitsSet90Days.size);
          setLast60_30DaysVisits(visitsCount60_30Days);
          setLast60_30DaysUniqueVisits(uniqueVisitsSet60_30Days.size);
          setLast90_30DaysVisits(visitsCount90_30Days);
          setLast90_30DaysUniqueVisits(uniqueVisitsSet90_30Days.size);
        });
    };
    uniqueUserCount();
  }, []);

  useEffect(() => {
    const pageVisitCount = async () => {
      fetch(
        `https://perpverse-default-rtdb.asia-southeast1.firebasedatabase.app/telemetry-page-visit.json`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          const dataMap = new Map();

          Object.keys(res).forEach((item) => {
            const sessionId = res[item].sessionId;

            if (dataMap.has(sessionId)) {
              dataMap.set(
                sessionId,
                `${dataMap.get(sessionId)} > ${res[item].pageName}`
              );
            } else {
              dataMap.set(sessionId, res[item].pageName);
            }
          });

          setPageVisit(dataMap);
        });
    };

    pageVisitCount();
  }, []);

  return (
    <div className={`container-fliud mt-5 ps-0 pe-0 ${classes.footerCard}`}>
      <div className="container">
        <div className={`row col-md-12 ${classes.footerCardContent}`}>
          {/* <div className="col-md-6">
            <div className="col-md-12 link-container">
              <a
                href="https://vigneshramamoorthy.prepverse.net"
                target="_blank"
                className="link"
              >
                <PermIdentityIcon />
                <span className="link-text">Vignesh Ramamoorthy</span>
                <OpenInNewIcon className="icon" />
              </a>
            </div>
            <div className="col-md-12 link-container">
              <a
                href="https://github.com/VigneshRamamoorthy1992"
                target="_blank"
                className="link"
              >
                <GitHubIcon />
                <span className="link-text">Github</span>
                <OpenInNewIcon className="icon" />
              </a>
            </div>
          </div> */}
          {/* <div className="col-md-12"> */}
          <div className="col-md-3 link-container">Looking to reachout?</div>
          <div className="col-md-3 link-container">
            <a
              href="https://twitter.com/prep_verse"
              target="_blank"
              className="link"
            >
              <TwitterIcon />
              <span className="link-text">Twitter</span>
              <OpenInNewIcon className="icon" />
            </a>
          </div>
          <div className="col-md-3 link-container">
            <a
              href="mailto:contact.prepverse@gmail.com"
              target="_blank"
              className="link"
            >
              <MailIcon />
              <span className="link-text">Mail</span>
              <OpenInNewIcon className="icon" />
            </a>
          </div>
          <div className="col-md-3 link-container">
            <a
              href="https://join.slack.com/t/prepverse/shared_invite/zt-12cmeti9i-u2L2J6qE_O3~in4O84pbaA"
              className="link"
              target="_blank"
            >
              <MessageIcon />
              <span className="link-text">Slack</span>
              <OpenInNewIcon className="icon" />
            </a>
            {/* </div> */}
          </div>
          <div className={`col-md-12 mt-2 mb-2 ${classes.gradientText}`}>
            Join our slack channel for any discussion or guidance on FAANG
            interviews
          </div>
          {showStats && (
            <div style={{ padding: "10px" }}>
              <Table aria-label="stats">
                <TableRow style={{ fontSize: "20px", fontWeight: "bold" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="stats-table-header"
                  >
                    Metric
                  </TableCell>
                  <TableCell align="left" className="stats-table-header">
                    Total
                  </TableCell>
                  <TableCell align="left" className="stats-table-header">
                    7 Days
                  </TableCell>
                  <TableCell align="left" className="stats-table-header">
                    30 Days
                  </TableCell>
                  <TableCell align="left" className="stats-table-header">
                    60 Days
                  </TableCell>
                  <TableCell align="left" className="stats-table-header">
                    90 Days
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Visits
                  </TableCell>
                  <TableCell align="left">{users.length}</TableCell>
                  <TableCell align="left">{last7DaysVisits}</TableCell>
                  <TableCell align="left">{last30DaysVisits}</TableCell>
                  <TableCell align="left">
                    {last60DaysVisits} Total - {last60_30DaysVisits} in 30 days
                  </TableCell>
                  <TableCell align="left">
                    {last90DaysVisits} Total - {last90_30DaysVisits} in 30 days
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Unique Visits
                  </TableCell>
                  <TableCell align="left">
                    {
                      users.filter(
                        (value, index, array) => array.indexOf(value) === index
                      ).length
                    }
                  </TableCell>
                  <TableCell align="left">{last7DaysUniqueVisits}</TableCell>
                  <TableCell align="left">{last30DaysUniqueVisits}</TableCell>
                  <TableCell align="left">
                    {last60DaysUniqueVisits} Total - {last60_30DaysUniqueVisits}{" "}
                    in 30 days
                  </TableCell>
                  <TableCell align="left">
                    {last90DaysUniqueVisits} Total - {last90_30DaysUniqueVisits}{" "}
                    in 30 days
                  </TableCell>
                </TableRow>
              </Table>
              <Table
                aria-label="page visit stats"
                style={{ marginTop: "100px" }}
              >
                <TableBody>
                  {[...pageVisit.keys()].map((key, index) => (
                    <TableRow
                      key={key + index.toString()}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                      <TableCell align="left">{pageVisit.get(key)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          <Divider className="mt-4" />
        </div>
        <Button
          onClick={() => {
            const passkey = prompt("enter creds: ");
            if (passkey === "keerthi") {
              setShowStats(!showStats);
            }
          }}
        >
          stats
        </Button>
      </div>
    </div>
  );
};

export default Footer;
