import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";

const light = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#2f4f4f",
    },
    secondary: {
      main: green[500],
    },
  },
  header: {
    color: "darkslategrey !important",
    fontWeight: "700 !important",
  },
  body: {
    color: "darkslategrey !important",
  },
  problemCatagory: {
    fontSize: "26px",
    padding: "20px",
    color: "darkslategrey !important",
    filter: "brightness(1)",
  },
  card: {
    "&::before": {
      backgroundColor: "0 0 0 / 10%",
      backdropFilter:
        "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
      content: '""',
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    border: "1px solid #fff",
    borderRadius: "15px",
    boxShadow: "0 0 1rem 0 rgb(0 0 0 / 10%)",
    fontSize: "2rem",
    height: "220px",
    overflow: "hidden",
    position: "relative",
    width: "100%",
    cursor: "pointer",
  },
  cardContent: {
    color: "#66686b",
    fontSize: "24px",
    marginLeft: "30px",
    marginTop: "20px",
    position: "relative",
  },
  cardContentCount: {
    color: "#66686b",
    fontSize: "24px",
    marginLeft: "30px",
    marginTop: "100px",
    position: "relative",
  },
  appBarColored: {
    background: "#bfc8d0 !important",
  },
  footerCard: {
    "&::before": {
      backgroundColor: "0 0 0 / 10%",
      backdropFilter:
        "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
      content: '""',
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    border: "1px solid #fff",
    boxShadow: "0 0 1rem 0 rgb(0 0 0 / 10%)",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  footerCardContent: {
    color: "#66686b",
    position: "relative",
    fontWeight: "bold",
  },
  footerKeywords: {
    padding: "2px 6px",
    background: "#e8e7e766",
    borderRadius: "4px",
    margin: "5px",
    display: "inline-grid",
  },
});

export default light;
